import React from "react"
import ArticleThumb from "../components/articleThumb"
import { Grid } from "theme-ui"
import { getStoryPath } from "../utils/path"

const StoriesList = ({ articles }) => {
  return (
    <Grid columns={[1, 2, 2, 4]} gap={[16, 32]}>
      {articles.map(article => (
        <ArticleThumb
          article={article}
          getPath={getStoryPath}
          key={article.id}
        />
      ))}
    </Grid>
  )
}

export default StoriesList
